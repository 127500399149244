/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

import "materialize-css/dist/css/materialize.min.css"
import "./css/styles.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />

        <main style={{
          margin: 0,
          width: `100%`
        }}>{children}</main>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
