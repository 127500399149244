import { Link } from "gatsby"
import React, { Component } from "react"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'

/*
  References
  1 .https://medium.com/@raravi/gatsby-and-the-window-variable-352ed558a8c0
  2. https://stackoverflow.com/questions/52527522/is-there-a-way-to-use-materializecss-with-gatsby-js (2nd answer)
  3. https://spectrum.chat/gatsby-js/general/error-95312-window-is-not-available-during-server-side-rendering~925acf07-2688-411a-84ee-f47242c089ff
*/

if (typeof window != `undefined`) {
  require("materialize-css/dist/js/materialize.min.js");
}

library.add(fas);

class Header extends Component {
  /*
    References
    1. https://stackoverflow.com/questions/53585346/materialize-css-with-react-initializing-components-using-react-lifecycle-method
    2. https://medium.com/@hamza.el/react-materialize-sidenav-in-4-steps-7365f6176b09
  */
  componentDidMount() {
    const options = {
      inDuration: 250,
      outDuration: 200,
      draggable: true
    };

    window.M.Sidenav.init(this.Sidenav, options);
  }

  render() {
    return (
      <>
        <header>
          <nav className="navbar-fixed white" role="navigation">
            <div className="nav-wrapper container">
              <Link to="/" className="brand-logo black-text text-font"></Link>
              <a href="!#" data-target="slide-out" className="sidenav-trigger">
                <FontAwesomeIcon icon={[`fas`, `bars`]} size="2x" />
              </a>
              <ul className="right hide-on-med-and-down">
                <li><Link className="btn navbtn" to="/about">About</Link></li>
                <li><Link className="btn navbtn" to="/furrfit">FurrFit</Link></li>
                <li><Link className="btn navbtn" to="https://blog.furrble.com/">Blog</Link></li>
                <li><Link className="btn navcon" to="https://prototype.furrble.com/">Try Furrble</Link></li>
              </ul>
            </div>
          </nav>
          <ul 
            ref={Sidenav => {
              this.Sidenav = Sidenav;
            }}
            id="slide-out" className="sidenav"
          >
            <li><Link className="sidenav-close" to="/">Home</Link></li>
            <li><Link className="sidenav-close" to="/about">About</Link></li>
            <li><Link className="sidenav-close" to="/furrfit">FurrFit</Link></li>
            <li><Link className="sidenav-close" to="https://blog.furrble.com/">Blog</Link></li>
            <li><Link className="sidenav-close" to="https://prototype.furrble.com/"><span className="pet-purple-text">Try Furrble</span></Link></li>
          </ul>
        </header>
      </>
    );
  }

}
// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: `Furrble`,
// }

export default Header
